import React from "react";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import SupportForm from "../../components/Forms/SupportForm";

const SupportPage = () => {
	return (
		<Layout>
			<SearchEngineOptimization title="Support" />
			<section className="section section--is-spacious">
				<div className="container container--is-extra-narrow">
					<SupportForm />
				</div>
			</section>
		</Layout>
	);
};

export default SupportPage;
