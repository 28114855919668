import React from "react";
import PolygonHeader from "../../images/polygons/polygon-header-default.svg";
import PropTypes from "prop-types";

const FormHeading = ({ title, headingLevel }) => {
	const HeadingLevelTag = `h${headingLevel ? headingLevel : "1"}`;
	return (
		<div className="form__heading">
			<div className="polygon">
				<img
					className="polygon__image"
					src={PolygonHeader}
					alt=""
					aria-hidden="true"
				/>
			</div>
			<HeadingLevelTag>{title}</HeadingLevelTag>
		</div>
	);
};

export default FormHeading;

FormHeading.propTypes = {
	title: PropTypes.string,
	headingLevel: PropTypes.string,
};
