import React from "react";
import FormHeading from "../../FormElements/FormHeading";
import HubspotForm from "react-hubspot-form";

function SupportForm() {
	return (
		<div className="form">
			<FormHeading title="Support" />
			<p>Did something break? Do you need help? We're here to help. Just let us know what's going on (with as much detail as you can) and we'll get crackin'.</p>
			<HubspotForm
				portalId="544161"
				formId="f1dc6925-edc7-43a5-b9e6-0199590bc072"
				region="na1"
				css=""
				submitButtonClass="btn btn--black-green"
			/>
		</div>
	);
}

export default SupportForm;
